import { createColumnHelper } from "@tanstack/react-table";
import {
  getHourlyPayloadCellClassName,
  IReportSensorData,
} from "./report-table.helper";
import { IHourlyPayload } from "../../../interfaces/reports";
import { sortingFunction } from "../kyps-table.helper";
import SensorDetailsSidebar from "../../kyps-sidebar/sensor-details-sidebar/sensor-details-sidebar";
import KypsTooltip from "../../kyps-tooltip/kyps-tooltip";
import { t, TFunction } from "i18next";
import {
  IdCell,
  RangeCell,
  TemperatureReportData,
} from "./report-table.styles";
import {
  ReportBodyCellContent,
  ReportBodyCellWrapper,
  ReportHeaderCellContent,
  ReportHeaderCellWrapper,
} from "./report-table.styles";
import SensorNameComponent from "../../sensor-name-component/sensor-name-component";
import { SensorStateVariantEnum } from "../../../interfaces/sensors";

export interface ICreateReportTableColumns {
  locationReportData: IReportSensorData[];
  isDisabled: boolean;
  openModal: (modalContent: React.ReactNode) => void;
  closeModal: () => void;
  t: TFunction<"translations", undefined>;
}

const { accessor } = createColumnHelper<IReportSensorData>();
export const createReportTableColumns = ({
  locationReportData,
  isDisabled,
  openModal,
  closeModal,
  t,
}: ICreateReportTableColumns) => {
  const hoursColumns = locationReportData.map((sensor) => {
    const hours = Object.keys(sensor).filter((i) => i.includes(":00"));

    return hours.map((item, index) => ({
      header: () => (
        <ReportHeaderCellWrapper className="hours">
          <ReportHeaderCellContent>{item}</ReportHeaderCellContent>
        </ReportHeaderCellWrapper>
      ),
      accessorKey: [item],
      size: 38,
      id: `${index}`,
      cell: ({ getValue }: { getValue: () => IHourlyPayload }) => {
        const cellValue = getValue();
        const cellClassName = getHourlyPayloadCellClassName(cellValue);

        return (
          <ReportBodyCellWrapper>
            <TemperatureReportData className={cellClassName}>
              {getDataCellValue(cellValue)}
            </TemperatureReportData>
          </ReportBodyCellWrapper>
        );
      },
    }));
  });

  return [
    accessor("sensorId", {
      header: t("tables.iPoint"),
      size: 142,
      sortingFn: (rowA, rowB) =>
        sortingFunction(rowA.original.sensorId, rowB.original.sensorId),
      cell: ({
        getValue,
        row: {
          index,
          original: { sensorName },
        },
      }) => {
        const value = `${index + 1}. ${getValue()}`;

        return (
          <SensorNameComponent
            name={value}
            subName={sensorName}
            isClickable={false}
            isActive={false}
          />
        );
      },
    }),

    accessor("range", {
      header: () => (
        <ReportHeaderCellWrapper>
          <ReportHeaderCellContent>
            {t("tables.reportRange")}
          </ReportHeaderCellContent>
        </ReportHeaderCellWrapper>
      ),
      size: 58,
      cell: ({ getValue }) => (
        <ReportBodyCellWrapper>
          <RangeCell>{getValue()}</RangeCell>
        </ReportBodyCellWrapper>
      ),
    }),

    ...hoursColumns[0],

    accessor("id", {
      header: ({ column }) => (
        <ReportHeaderCellWrapper style={{ width: `${column.getSize()}px` }}>
          <ReportHeaderCellContent>
            {t("tables.sensorId")}
          </ReportHeaderCellContent>
        </ReportHeaderCellWrapper>
      ),
      size: 57,
      cell: ({
        getValue,
        row: {
          original: {
            iPoint,
            zone,
            zoneDeleted,
            installationPointDeleted,
            id,
            showHumidity,
          },
        },
      }) => {
        const deleted = zoneDeleted || installationPointDeleted;

        return (
          <ReportBodyCellWrapper>
            <ReportBodyCellContent className="has-tooltip">
              <IdCell
                onClick={() =>
                  openModal(
                    <SensorDetailsSidebar
                      closeModal={closeModal}
                      defaultIPoint={iPoint}
                      defaultZone={zone}
                      sensorNumber={getValue()}
                      showHumidity={showHumidity}
                      isDisabled={isDisabled}
                    />
                  )
                }
              >
                <KypsTooltip tooltipValue={deleted ? `${id} deleted` : id}>
                  {id}
                </KypsTooltip>
              </IdCell>
            </ReportBodyCellContent>
          </ReportBodyCellWrapper>
        );
      },
    }),

    accessor("battery", {
      header: ({ column }) => (
        <ReportHeaderCellWrapper style={{ width: `${column.getSize()}px` }}>
          <ReportHeaderCellContent>
            {t("tables.battery")}
          </ReportHeaderCellContent>
        </ReportHeaderCellWrapper>
      ),
      size: 44,
      cell: ({ getValue }) => (
        <ReportBodyCellWrapper>
          <ReportBodyCellContent>
            {getValue() ? getValue() + "%" : "-"}
          </ReportBodyCellContent>
        </ReportBodyCellWrapper>
      ),
    }),
  ];
};

const getDataCellValue = ({ state, value }: IHourlyPayload) => {
  switch (state) {
    case SensorStateVariantEnum.MAINTENANCE:
      return (
        <KypsTooltip
          tooltipValue={t("statsCards.sensorState.maintenance")}
          children="🛠"
        />
      );
    case SensorStateVariantEnum.CHARGING:
      return (
        <KypsTooltip
          tooltipValue={t("statsCards.sensorState.charging")}
          children="⚡"
        />
      );
    default:
      return value?.toFixed(1) ?? "null";
  }
};
